// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/ms/webdev/docupop-landers/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cs-thank-you-tsx": () => import("/Users/ms/webdev/docupop-landers/src/pages/cs-thank-you.tsx" /* webpackChunkName: "component---src-pages-cs-thank-you-tsx" */),
  "component---src-pages-forgiveness-tsx": () => import("/Users/ms/webdev/docupop-landers/src/pages/forgiveness.tsx" /* webpackChunkName: "component---src-pages-forgiveness-tsx" */),
  "component---src-pages-forgivness-bhf-tsx": () => import("/Users/ms/webdev/docupop-landers/src/pages/forgivness-bhf.tsx" /* webpackChunkName: "component---src-pages-forgivness-bhf-tsx" */),
  "component---src-pages-index-js": () => import("/Users/ms/webdev/docupop-landers/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lander-tsx": () => import("/Users/ms/webdev/docupop-landers/src/pages/lander.tsx" /* webpackChunkName: "component---src-pages-lander-tsx" */),
  "component---src-pages-loan-forgiveness-ga-tsx": () => import("/Users/ms/webdev/docupop-landers/src/pages/loan-forgiveness-ga.tsx" /* webpackChunkName: "component---src-pages-loan-forgiveness-ga-tsx" */),
  "component---src-pages-lower-payments-bhf-tsx": () => import("/Users/ms/webdev/docupop-landers/src/pages/lower-payments-bhf.tsx" /* webpackChunkName: "component---src-pages-lower-payments-bhf-tsx" */),
  "component---src-pages-page-2-js": () => import("/Users/ms/webdev/docupop-landers/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-private-thank-you-tsx": () => import("/Users/ms/webdev/docupop-landers/src/pages/private-thank-you.tsx" /* webpackChunkName: "component---src-pages-private-thank-you-tsx" */),
  "component---src-pages-speak-with-an-advisor-bhf-tsx": () => import("/Users/ms/webdev/docupop-landers/src/pages/speak-with-an-advisor-bhf.tsx" /* webpackChunkName: "component---src-pages-speak-with-an-advisor-bhf-tsx" */),
  "component---src-pages-speak-with-an-advisor-tsx": () => import("/Users/ms/webdev/docupop-landers/src/pages/speak-with-an-advisor.tsx" /* webpackChunkName: "component---src-pages-speak-with-an-advisor-tsx" */),
  "component---src-pages-thank-you-bhf-tsx": () => import("/Users/ms/webdev/docupop-landers/src/pages/thank-you-bhf.tsx" /* webpackChunkName: "component---src-pages-thank-you-bhf-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("/Users/ms/webdev/docupop-landers/src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/ms/webdev/docupop-landers/.cache/data.json")

